import { makeStyles } from '@material-ui/core/styles';
import { Grid, Theme, Typography } from '@material-ui/core';

import GHButton from '../common/GHButton';
import GHCard from '../common/GHCard';
import GHContent from '../common/GHContent';
import GHRoot from '../common/GHRoot';

const useStyles = makeStyles((theme: Theme) => {
  return {
    container: {
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      marginBottom: theme.spacing(4)
    },
    textContainer: {
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down('sm')]: {
        paddingTop: theme.spacing(2)
      }
    }
  };
});

// names as such because of the old bootstrap, call to action element from back in the day
const Jumbotron = () => {
  const classes = useStyles();

  return (
    <GHRoot>
      <GHContent>
        <Grid container className={classes.container}>
          <Grid
            item
            md={12}
            container
            className={classes.textContainer}
            justifyContent="center"
          >
            <GHCard>
              <Typography variant="h1">Supporting great governance</Typography>
              <Typography variant="body1" gutterBottom>
                Everything your board(s) needs to govern{' '}
                <i>
                  <b>effectively</b>
                </i>{' '}
                at your school or trust - all in 1 place.
              </Typography>
              <Grid item container spacing={1} justifyContent="center">
                <Grid item>
                  <GHButton
                    data-tour-id="signup-button"
                    variant="contained"
                    href="/signup"
                  >
                    Contact us for a free trial
                  </GHButton>
                </Grid>
                <Grid item>
                  <a
                    href="https://webinars.governorhub.com/governorhub-in-30"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <GHButton variant="contained">
                      Join our next live demo
                    </GHButton>
                  </a>
                </Grid>
              </Grid>
            </GHCard>
          </Grid>
        </Grid>
      </GHContent>
    </GHRoot>
  );
};

export default Jumbotron;
