import { Tab, TabProps, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.secondary.dark,
      borderColor: theme.palette.secondary.dark,
      color: theme.palette.text.secondary,
      transition: '0.4s',
      textTransform: 'none',
      padding: theme.spacing(0, 1),
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main
      }
    }
  };
});

export const GHTab = (props: TabProps) => {
  const classes = useStyles();
  return <Tab classes={{ root: classes.root }} {...props} />;
};
