import { ReactElement, ReactNode } from 'react';
import Image from 'next/image';

import classNames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Theme, Typography, useMediaQuery } from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      color: theme.palette.secondary.dark,
      backgroundColor: theme.palette.background.default
    },
    container: {
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(12)
    },
    image: {
      height: '100%',
      width: '100%',
      borderRadius: theme.shape.borderRadius
    },
    textContainer: {
      [theme.breakpoints.up('md')]: {
        paddingTop: theme.spacing(12),
        paddingBottom: theme.spacing(12),
        paddingRight: theme.spacing(4)
      }
    },
    list: {
      listStyle: 'none',
      display: 'flex',
      flexDirection: 'column',
      paddingInlineStart: 0
    },
    listItem: {
      display: 'flex'
    },
    listItemIcon: {
      marginTop: theme.spacing(0.4)
    },
    listItemText: {
      paddingLeft: theme.spacing(2)
    }
  };
});

export interface GHSectionProps {
  mediaSrc: StaticImageData | string;
  mediaClass?: string;
  headerText: ReactNode;
  preListText: ReactNode;
  listText: ReactNode[] | null;
  listIcon: IconProp | null;
  postListChildren?: ReactElement;
}

const GHSection = ({
  mediaSrc,
  mediaClass,
  headerText,
  preListText,
  listText,
  listIcon,
  postListChildren
}: GHSectionProps) => {
  const matchesSm = useMediaQuery('(max-width:960px)');
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid
        container
        className={classes.container}
        direction={matchesSm ? 'column' : 'row'}
        justifyContent={matchesSm ? 'center' : 'space-between'}
      >
        <Grid item xs={12} md={4} className={classes.textContainer}>
          {headerText && <Typography variant="h2">{headerText}</Typography>}
          {preListText && (
            <Typography variant="body1">{preListText}</Typography>
          )}
          {listText && (
            <ul className={classes.list}>
              {listText.map((text, index) => {
                return (
                  <li key={index} className={classes.listItem}>
                    {listIcon && (
                      <FontAwesomeIcon
                        className={classes.listItemIcon}
                        icon={listIcon}
                      />
                    )}
                    <Typography
                      variant="body2"
                      className={classes.listItemText}
                    >
                      {text}
                    </Typography>
                  </li>
                );
              })}
            </ul>
          )}
          {postListChildren}
        </Grid>
        <Grid item xs={12} md={8}>
          <Box
            sx={{
              position: 'relative',
              width: '100%',
              height: matchesSm ? '500px' : '100%'
            }}
          >
            <Image
              layout="fill"
              objectFit="contain"
              src={mediaSrc}
              className={
                mediaClass
                  ? classNames(classes.image, mediaClass)
                  : classes.image
              }
            />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default GHSection;
