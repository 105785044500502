import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Theme, Tabs, Box } from '@material-ui/core';

import { faArrowAltCircleRight } from '@fortawesome/free-regular-svg-icons';

import GHSection from '../common/GHSection';

import GHContent from '../common/GHContent';
import { GHTab } from '../common/GHTab';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      color: theme.palette.secondary.dark,
      backgroundColor: theme.palette.background.default,
      position: 'relative'
    },
    tabsFlexContainer: {
      flexWrap: 'wrap',
      justifyContent: 'center',
      gap: 16
    },
    indicator: {
      display: 'none'
    },
    content: {
      paddingTop: theme.spacing(6),
      [theme.breakpoints.up('sm')]: {
        paddingTop: theme.spacing(8)
      }
    }
  };
});

const adminstrationItem = {
  mediaSrc:
    'https://static.governorhub.com/marketing/showcase-gallery/administration.png',
  headerText: 'Administration',
  preListText: '',
  listText: [
    'Store documents securely',
    'Get organised with a shared calendar',
    'Communicate with your peers',
    'Set your constitution and track your roles and committees'
  ],
  listIcon: faArrowAltCircleRight
};

const complianceItem = {
  mediaSrc:
    'https://static.governorhub.com/marketing/showcase-gallery/compliance.png',
  headerText: 'Compliance',
  preListText: '',
  listText: [
    'Track declarations of interest and confirmations',
    'Record terms of office',
    "Monitor what's happening, with downloadable reports"
  ],
  listIcon: faArrowAltCircleRight
};

const developmentItem = {
  mediaSrc:
    'https://static.governorhub.com/marketing/showcase-gallery/board-development.png',
  headerText: 'Development',
  preListText: '',
  listText: [
    'Build confidence with our on-demand online training',
    'Access our practical resources and templates',
    'Stay informed with email updates and support from our team'
  ],
  listIcon: faArrowAltCircleRight
};

const evidencingItem = {
  mediaSrc:
    'https://static.governorhub.com/marketing/showcase-gallery/evidencing.png',
  headerText: 'Effectiveness',
  preListText: '',
  listText: [
    'Measure and monitor board effectiveness',
    'Log governor visits and reports',
    'Provide an instant body of evidence for when you need it most'
  ],
  listIcon: faArrowAltCircleRight
};

const sectionItems = [
  adminstrationItem,
  complianceItem,
  developmentItem,
  evidencingItem
];

const ShowcaseGallery = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const classes = useStyles();

  return (
    <div>
      <div className={classes.root}>
        <Tabs
          classes={{
            flexContainer: classes.tabsFlexContainer,
            indicator: classes.indicator
          }}
          value={selectedTab}
          onChange={(_, newValue) => setSelectedTab(newValue)}
        >
          {sectionItems.map((item, index) => (
            <GHTab key={index} label={item.headerText} value={index} />
          ))}
        </Tabs>
        {sectionItems.map((item, index) => (
          <Box
            key={index}
            sx={{ display: selectedTab === index ? 'block' : 'none' }}
          >
            <GHContent className={classes.content}>
              <GHSection {...item} />
            </GHContent>
          </Box>
        ))}
      </div>
    </div>
  );
};

export default ShowcaseGallery;
